<template>
  <div class="app-container">
    <img class="logo" alt="Safety Takes Everyone" src="./assets/logo@2x.png">
    <p>Select your business unit to activate the appropriate WeTransfer link and
      upload your video.</p>
    <select v-model="selected" @change="updateButton">
      <option value="" selected disabled>Select</option>
      <option v-for="option in options" :value="option.value" :key="option.value">
        {{ option.text }}
      </option>
    </select>
    <Button :content="content" :link="link" />
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  name: 'App',
  components: {
    Button
  },
  data() {
    return {
      selected: '',
      content: '', 
      link: '',
      options: [
        { text: 'Store', value: 'https://thdsafety.wetransfer.com', data: 'Store' },
        { text: 'Home Services', value:
          'https://thdhomeservices.wetransfer.com', data: 'Home Services' },
        { text: 'MET', value: 'https://safetydemo.wetransfer.com', data: 'MET' },
        { text: 'Rental/Equipment Services', value:
          'https://thdrental.wetransfer.com', data: 'Rental' },
        { text: 'Supply Chain', value: 'https://thdsupplychain.wetransfer.com',
          data: 'Supply Chain' },
      ]
    }
  },
  methods: {
    updateButton() {
      let option = this.options.filter(o => o.value === this.selected);
      this.content = option[0].data
      this.link = option[0].value
    }
  }
}
</script>

<style>
html,body {
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

#app {
  background: #000;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 0 auto;
}

.logo {
  margin-top: 67px;
  width: 299px;
}

p {
  line-height: normal;
  margin-top: 49px;
  width: 256px;
  text-align: center;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url('assets/down-arrow.png') no-repeat 94%;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  min-width: 201px;
  width: auto;
  padding: 10px 36px 10px 14px;
  font-size: 14px;
  margin-top: 30px;
}
</style>
