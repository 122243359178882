<template>
  <a :class="{ 'orange': link }" :href="link">{{ content.length ? `${content} WeTransfer`: "WeTransfer" }} <img src="../assets/arrow-right.png" /></a>
</template>

<script>
export default {
props: {
  content: {
    type: String,
    required: false,        
  },
  link: {
    type: String,
    required: false
  }
}
}
</script>

<style scoped>
a {
  align-items: center;
  background: #888786;
  border-radius: 40px;
  border: none;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
  margin-top: 53px;
  padding: 23px 37px 21px;
  min-width: 252px;
  width: auto;
  height: 68px;
  opacity: 0.44;
  text-decoration: none;
  pointer-events: none;
}

.orange {
  pointer-events: all;
  background: #F48126;
  opacity: 1.0;
}
</style>
